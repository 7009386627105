<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card card-custom gutter-b example example-compact">
        <div
          class="card-header d-flex align-items-center justify-content-between"
        >
          <div class="card-title">
            <h3 class="card-label">
              {{ $t('MENU.MAIN_ASSETS.REVALUATION_AV') }}
            </h3>
          </div>
          <div class="card-button">
            <v-btn
              class="mx-2"
              target="_blank"
              to="/annualremark/create"
              small
              v-if="showBtn"
              color="primary"
            >
              Yillik qayta baxolash
            </v-btn>
          </div>
        </div>
        <div class="card-body">
          <div>
            <v-data-table
              :loading="isLoading"
              :headers="headers"
              :items="getMainAssets"
              no-data-text="Malumot kiritilmagan"
              disable-pagination
              single-select
              @click:row="rowClick"
              item-key="id"
              :items-per-page="5"
              class="elevation-1 row-pointer"
              hide-default-footer
            >
              <template v-slot:[`item.action`]="{ item }">
                <action :index="item"></action>
              </template>
              <template v-slot:[`item.year`]="{ item }">
                {{ item.year | formatDate }}
              </template>
            </v-data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import action from './Action'
export default {
  data() {
    return {
      counter: 0, // count the clicks
      timer: null, // Needs to be specified here so it can be accessed on both clicks
      selectedId: -1,
      showBtn: false
    }
  },
  watch: {
    getMainAssets: function (val) {
      var d = new Date()
      var n = d.getFullYear()
      const data = val.filter((x) => x.year.split('-')[0] == n - 1)
      if (data.length == 0) {
        this.showBtn = true
      }
    }
  },
  created() {
    this.$store.dispatch('annualRemark')
  },
  computed: {
    headers() {
      return [
        {
          text: 'ID',
          value: 'id'
        },
        {
          text: this.$t('TABLE_HEADER.NAME'),
          value: 'name'
        },
        {
          text: this.$t('TABLE_HEADER.YEAR') + ' ' + '#',
          value: 'year'
        },
        {
          text: '',
          value: 'action'
        }
      ]
    },
    getMainAssets() {
      const data = this.$store.state.requests.annualRemark
      return data
    },
    isLoading() {
      return this.$store.state.requests.isLoading
    }
  },
  methods: {
    rowClick: function (item, row) {
      var self = this
      this.counter++
      if (this.counter == 1) {
        this.timer = setTimeout(function () {
          self.counter = 0
          row.select(true)
          this.selectedId = item.id
        }, 200)
      } else {
        clearTimeout(this.timer)
        self.counter = 0
        this.$router.push('/annualremark/details/' + item.id + '/')
        row.select(false)
      }
    },
    newWindow() {
      let routeData = this.$router.resolve({ name: 'Annual remark' })
      window.open(routeData.href, '_blank')
    }
  },
  // SET_BREADCRUMB
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t('MENU.MAIN_ASSETS.TITLE') },
      {
        title: this.$t('MENU.MAIN_ASSETS.REVALUATION_AV')
      }
    ])
  },
  components: {
    action
  }
}
</script>

<style scoped>
.v-application .elevation-1 {
  box-shadow: none !important;
}

.v-data-table /deep/ .v-data-table__wrapper > table > thead > tr > th {
  font-size: 1rem !important;
  color: #000 !important;
  font-weight: 500 !important;
}
table {
  border-collapse: collapse;
  width: 100%;
}
.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 1rem !important;
  color: #000 !important;
  height: 60px !important;
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow-x: scroll !important;
  white-space: nowrap !important;
  min-height: 300px;
  width: 100%;
}
</style>
